import "./App.css";
import Footer from "./components/footer/Footer";
import HeroSection from "./components/hero-section/HeroSection";
import Join from "./components/join/Join";
import Plans from "./components/plans/Plans";
import Privacy from "./components/privacy/Privacy";
import Programs from "./components/programs/Programs";
import Reasons from "./components/reasons/Reasons";
import Testimonials from "./components/testimonials/Testimonials";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter basename="gym-website"> */}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <Programs />
                <Reasons />
                <Plans />
                <Testimonials />
                <Join />
                <Footer />
              </>
            }
          />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
