import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h2 className="privacy-header">Privacy Policy Effective Date: 29-11-24</h2>
      <h3 className="privacy-subheader">Your privacy is important to us.</h3>
      <p className="privacy-text">
        This Privacy Policy explains how Quick Solution Key collects, uses, and
        protects your information when you use our mobile application, the Quick
        Solution Key. By using the App, you agree to the practices described in
        this Privacy Policy.
      </p>

      <section className="privacy-section">
        <h3 className="section-title">1. Information We Collect</h3>
        <p className="privacy-text">
          We collect the following types of information:
        </p>
        <ul className="privacy-list">
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, and other contact details when you register an account. Payment
            details when subscribing to premium features (processed securely through
            third-party providers).
          </li>
          <li>
            <strong>Health and Fitness Data:</strong> Weight, height, age, fitness
            goals, workout logs, and nutrition data to customize your experience.
          </li>
          <li>
            <strong>Device Information:</strong> Device type, operating system, app
            usage data, and location (if enabled).
          </li>
          <li>
            <strong>Third-Party Integrations:</strong> Data from fitness trackers,
            wearables, or health apps that you connect to the App.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">2. How We Use Your Information</h3>
        <p className="privacy-text">
          We use the information we collect to:
        </p>
        <ul className="privacy-list">
          <li>Provide and improve our App and services.</li>
          <li>Personalize your fitness plans and recommendations.</li>
          <li>Send updates, notifications, and promotional offers.</li>
          <li>Process payments for premium features or subscriptions.</li>
          <li>Ensure App security and prevent unauthorized access.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">3. Information Sharing and Disclosure</h3>
        <p className="privacy-text">
          We do not sell or rent your personal information. We may share your data:
        </p>
        <ul className="privacy-list">
          <li>With service providers for analytics, payment processing, or app functionality.</li>
          <li>When required by law or to protect our legal rights.</li>
          <li>With your consent for specific integrations or third-party services.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">4. Data Security</h3>
        <p className="privacy-text">
          We use industry-standard security measures to protect your data. However, no
          method of transmission or storage is 100% secure. Please ensure you protect
          your login credentials.
        </p>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">5. Your Choices</h3>
        <p className="privacy-text">
          <strong>Account Information:</strong> You can update or delete your account
          at any time in the App settings.
        </p>
        <p className="privacy-text">
          <strong>Permissions:</strong> You can disable access to certain features
          like location or fitness data in your device settings.
        </p>
        <p className="privacy-text">
          <strong>Marketing Communication:</strong> Opt out of promotional emails by
          using the "Unsubscribe" link in our emails.
        </p>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">6. Children's Privacy</h3>
        <p className="privacy-text">
          The App is not intended for users under the age of 13. We do not knowingly
          collect data from children.
        </p>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">7. Changes to This Privacy Policy</h3>
        <p className="privacy-text">
          We may update this Privacy Policy periodically. We will notify you of changes
          by updating the date at the top of this page.
        </p>
      </section>

      <section className="privacy-section">
        <h3 className="section-title">8. Contact Us</h3>
        <p className="privacy-text">
          If you have questions about this Privacy Policy, please contact us:
        </p>
        <ul className="contact-list">
          <li>Email: info@quicksolutionkey.com</li>
          <li>Phone: +91 83494 44345</li>
          <li>Address: 98, G/F, Sarai Jullena, New Friends Colony, Delhi-110025</li>
        </ul>
      </section>

      <p className="privacy-text">
        By using our App, you agree to the terms of this Privacy Policy. Thank you for
        choosing Quick Solution Key to support your fitness journey!
      </p>
    </div>
  );
};

export default Privacy;